import React from "react"
import {
  Box,
  Drawer,
  DrawerContent,
  IDrawer,
  Flex,
  DrawerOverlay,
} from "@chakra-ui/core"
import IconButton from "../Buttons/IconButton"
import SmallCaps from "../typography/SmallCaps"

type BottomDrawerProps = IDrawer & {
  heading: string
}

export default function BottomDrawer({
  heading,
  children,
  ...props
}: BottomDrawerProps) {
  return (
    <Drawer placement="bottom" size="md" {...props}>
      <DrawerOverlay />
      <DrawerContent borderTopLeftRadius="1.5rem" borderTopRightRadius="1.5rem">
        <Box pos="relative">
          <IconButton
            pos="absolute"
            top="1.625rem"
            right="1.75rem"
            aria-label="Close"
            icon="closeLarge"
            color="dawn"
            size="1.25rem"
            px="0"
            onClick={props.onClose}
          />
          <Flex
            pt="2.3125rem"
            px="2.375rem"
            justifyContent="center"
            alignItems="center"
          >
            <SmallCaps size="lg" fontWeight="bold" color="dusk">
              {heading}
            </SmallCaps>
          </Flex>
          {children}
        </Box>
      </DrawerContent>
    </Drawer>
  )
}
