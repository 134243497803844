import React from "react"
import {
  getJudgemeProduct,
  getJudgemeAvgRating,
  getJudgemeReviews,
  GetJudgemeReviewsResponseType,
} from "../api/reviews"
import R from "ramda"
import get from "lodash/get"
import find from "lodash/find"
import memoize from "lodash/memoize"
import { JudgeMeReview } from "typings"
import { usePrevious } from "@chakra-ui/core"

type JudgeMeProduct = {
  product: {
    excluded: boolean
    external_id: number
    handle: string
    id: number
    image_url: string
    in_store: boolean
    medium_image_url: string
    path: string | null
    small_image_url: string
    title: string
    vendor: string
  }
}

export const getJudgemeProductMemo = memoize(getJudgemeProduct)
export const getJudgemeAvgRatingMemo = memoize(getJudgemeAvgRating)
export const getJudgemeReviewsMemo = memoize(
  getJudgemeReviews,
  (productId, page, pageSize) => `${productId}_${page}_${pageSize}`
)

type UseJudgeMeReviewsOptions = {
  /**
   * Load data on demand
   */
  lazy?: boolean
  /**
   * Skip pulling reviews. Just fetch average rating
   */
  minimal?: boolean
  page?: number
  pageSize?: number
}

type JudgeMeReviewsData = {
  productHandle: string
  avgRating?: number
  numReviews?: number
  seoReviews?: JudgeMeReview[]
  goodReview?: JudgeMeReview
  judgemeProduct?: JudgeMeProduct
}

const getJudgemeProductData = async (
  productHandle: string,
  { minimal = false, page = 0, pageSize = 10 }
) => {
  try {
    const ratingData = await getJudgemeAvgRatingMemo(productHandle)
    const badgeHtml: string = get(ratingData, "badge", "")
    const el = document.createElement("div")
    el.innerHTML = badgeHtml.trim()

    const avgRating = el.firstElementChild
      ? el.firstElementChild.getAttribute("data-average-rating")
      : ""
    const numReviews = el.firstElementChild
      ? el.firstElementChild.getAttribute("data-number-of-reviews")
      : ""

    const results: JudgeMeReviewsData = {
      productHandle,
      avgRating: parseFloat(avgRating || "0"),
      numReviews: parseInt(numReviews || "0"),
    }

    if (!minimal) {
      const productData = await getJudgemeProductMemo(productHandle)
      const productId = get(productData, "product.id")
      const reviewData = (await getJudgemeReviewsMemo(
        productId,
        page,
        pageSize
      )) as GetJudgemeReviewsResponseType
      const reviews = get(reviewData, "reviews", [])

      if (reviews.length) {
        results.seoReviews = reviews as JudgeMeReview[]
      }
      results.judgemeProduct = productData

      if (results?.seoReviews?.length) {
        const goodReview = find(
          results.seoReviews,
          ({ rating }) => rating === 5
        )

        if (goodReview) {
          results.goodReview = goodReview
        }
      }
    }

    return results
  } catch (error) {
    console.error(error)
    return { productHandle } as JudgeMeReviewsData
  }
}

export function useBulkJudgeMeReviews(
  productHandles: string[],
  {
    lazy = false,
    minimal = false,
    pageSize = 10,
  }: UseJudgeMeReviewsOptions = {}
) {
  const previousProductHandles = usePrevious(productHandles)
  const [fetched, setFetched] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [products, setProducts] = React.useState<JudgeMeReviewsData[] | null>(
    null
  )

  const getJudgemeData = async () => {
    setIsLoading(true)
    setFetched(true)
    const results = await Promise.all(
      productHandles.map((productHandle) =>
        getJudgemeProductData(productHandle, { minimal, pageSize })
      )
    )
    setProducts(results)
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (!lazy && !R.equals(productHandles, previousProductHandles)) {
      getJudgemeData()
    } else {
      setFetched(false)
    }
  }, [productHandles])

  const numReviews = products?.length
    ? products?.reduce((acc, product) => {
        return acc + (product.numReviews || 0)
      }, 0)
    : 0

  const avgRating = products?.length
    ? products?.reduce((acc, product) => {
        return acc + (product.avgRating || 0)
      }, 0) / products.length
    : 0

  return {
    isLoading,
    fetched,
    getJudgemeData,
    products,
    numReviews,
    avgRating,
  }
}

export default (
  productHandle,
  {
    lazy = false,
    minimal = false,
    pageSize = 10,
  }: UseJudgeMeReviewsOptions = {}
) => {
  const [
    judgemeProduct,
    setJudgemeProduct,
  ] = React.useState<JudgeMeProduct | null>(null)
  const [avgRating, setAvgRating] = React.useState(0.0)
  const [numReviews, setNumReviews] = React.useState(0)
  const [seoReviews, setSeoReviews] = React.useState<JudgeMeReview[]>([])
  const [goodReview, setGoodReview] = React.useState<JudgeMeReview | null>(null)
  const [fetched, setFetched] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingMore, setIsLoadingMore] = React.useState(false)
  const page = React.useRef(1)

  const getJudgemeData = async () => {
    try {
      page.current = 1
      setFetched(true)
      setIsLoading(true)
      setIsLoadingMore(false)
      const data = await getJudgemeProductData(productHandle, {
        page: 1,
        minimal,
      })

      setAvgRating(data.avgRating !== undefined ? data.avgRating : 0)
      setNumReviews(data.numReviews !== undefined ? data.numReviews : 0)
      setJudgemeProduct(
        data.judgemeProduct !== undefined ? data.judgemeProduct : null
      )
      setSeoReviews(data.seoReviews !== undefined ? data.seoReviews : [])
      setGoodReview(data.goodReview !== undefined ? data.goodReview : null)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (!lazy) {
      getJudgemeData()
    } else {
      setFetched(false)
    }
  }, [productHandle])

  const loadMore = async () => {
    setIsLoadingMore(true)
    page.current++

    if (judgemeProduct?.product.id) {
      const newReviews = [...seoReviews]
      const reviewData = (await getJudgemeReviewsMemo(
        judgemeProduct.product.id.toString(),
        page.current,
        pageSize
      )) as GetJudgemeReviewsResponseType
      const reviews = get(reviewData, "reviews", []) as JudgeMeReview[]

      if (reviews.length) {
        newReviews.push(...reviews)
      }

      setSeoReviews(newReviews)
    }

    setIsLoadingMore(false)
  }

  return {
    judgemeProduct,
    avgRating,
    numReviews,
    seoReviews,
    goodReview,
    isLoading,
    fetched,
    getJudgemeData,
    loadMore,
    hasMoreToLoad: seoReviews.length < numReviews,
    isLoadingMore,
  }
}
