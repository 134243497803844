import React from "react"
import {
  Box,
  Flex,
  FlexProps,
  Icon,
  PseudoBox,
  useDisclosure,
} from "@chakra-ui/core"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"
import ToolTip from "../ToolTip"
import GridSelectOption from "../GridSelectOption"
import BottomDrawer from "../BottomDrawer"
import { isColorWhite } from "../../utils"

export interface ColorType {
  colorId: string
  colorCode: string
  isDisabled?: boolean
}

export interface GenericColorPickerProps {
  colors: Array<ColorType>
  selectedColorId: string
  onClick: (colorId: string) => void
}

export const ColorPickerMobile: React.FC<
  Omit<FlexProps, "onClick"> & GenericColorPickerProps
> = ({ colors, selectedColorId, onClick, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={onOpen}
        {...props}
      >
        <Box
          bg={
            colors?.find((color) => color.colorId === selectedColorId)
              ?.colorCode ?? "primary"
          }
          borderRadius="1.25rem"
          h="1.25rem"
          w="1.25rem"
          mr="0.5rem"
        />
        <Icon
          name="chevron"
          size="1.25rem"
          transform="rotate(-90deg)"
          color="dawn"
        />
      </Flex>
      <BottomDrawer heading="Colors" isOpen={isOpen} onClose={onClose}>
        <Flex
          align="stretch"
          pt="2.1875rem"
          pb="2.5rem"
          whiteSpace="nowrap"
          overflowX="auto"
        >
          <Flex align="flex-start" justify="center" px="3.25rem">
            {colors?.map((color, index) => (
              <GridSelectOption
                key={color.colorId}
                active={color.colorId === selectedColorId}
                option={{
                  value: color.colorId,
                  name: color.colorId,
                  backgroundColor: color.colorCode,
                }}
                onClick={() => {
                  onClose()
                  onClick(color.colorId)
                }}
                ml={index === 0 ? "0" : "0.25rem"}
                w="auto"
                flexShrink={0}
                flexGrow={0}
                maxWidth="6.25rem"
                isDisabled={color.isDisabled}
              />
            ))}
          </Flex>
        </Flex>
      </BottomDrawer>
    </>
  )
}

type BorderType = "shadow" | "solid"

const ColorBlock: React.FC<
  ColorType & {
    selected: boolean
    borderType?: BorderType
    onClick: (colorId: string) => void
  }
> = ({
  colorCode,
  colorId,
  isDisabled,
  selected,
  borderType = "shadow",
  onClick,
}) => (
  <ToolTip
    label={colorId}
    showDelay={300}
    shouldWrapChildren={false}
    zIndex={1000}
  >
    <PseudoBox
      role="button"
      d="flex"
      justifyContent="center"
      alignItems="center"
      h="1.75rem"
      w="1.75rem"
      borderRadius="1.75rem"
      m="0 0"
      cursor={isDisabled ? "not-allowed" : "pointer"}
      _hover={{
        backgroundColor: "dividerLine",
      }}
      // @ts-ignore
      disabled={isDisabled}
      opacity={isDisabled ? 0.5 : 1}
      transition="opacity 200ms ease"
      onClick={!isDisabled ? () => onClick(colorId) : undefined}
      {...(borderType === "shadow" && selected
        ? { boxShadow: "0 0.2rem 1.25rem rgba(0, 0, 0, 0.15)" }
        : {})}
      {...(borderType === "solid" && selected
        ? {
            border: "1px solid",
            borderColor: "night",
          }
        : {})}
    >
      <svg
        viewBox="0 0 36 36"
        width="1rem"
        height="1rem"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="18"
          cy="18"
          r="16"
          shapeRendering="geometricPrecision"
          fill={colorCode}
          strokeWidth="2px"
          stroke={isColorWhite(colorCode) ? "#000000" : "transparent"}
        />
      </svg>
    </PseudoBox>
  </ToolTip>
)

export const ColorPickerDesktop: React.FC<
  Omit<FlexProps, "onClick"> &
    GenericColorPickerProps & { borderType: BorderType | undefined }
> = ({ colors, selectedColorId, borderType, onClick, ...props }) => (
  <Flex flexWrap="wrap" {...props}>
    {colors?.map((color) => (
      <ColorBlock
        {...color}
        key={color.colorId}
        selected={color.colorId === selectedColorId}
        onClick={onClick}
        borderType={borderType}
      />
    ))}
  </Flex>
)

export type OnColorPickFunctionType = (color: ColorType | undefined) => void

interface ColorPickerProps {
  colors: Array<ColorType>
  onColorClick: OnColorPickFunctionType
  selectedColorId?: string
  borderType?: BorderType
}

const ColorPicker: React.FC<Omit<FlexProps, "onClick"> & ColorPickerProps> = ({
  onColorClick,
  ...props
}) => {
  const [selectedColorId, setSelectedColorId] = React.useState<string>(
    props.selectedColorId ?? props.colors[0].colorId
  )
  const handleColorClick = (colorId: string) => {
    setSelectedColorId(colorId)
    onColorClick(props.colors.find(({ colorId: cId }) => colorId === cId))
  }

  React.useEffect(() => {
    if (props.selectedColorId) {
      setSelectedColorId(props.selectedColorId)
    }
  }, [props.selectedColorId])

  return (
    <>
      <LaptopDownOnly>
        <ColorPickerMobile
          {...props}
          selectedColorId={selectedColorId}
          onClick={handleColorClick}
        />
      </LaptopDownOnly>
      <LaptopUpOnly>
        <ColorPickerDesktop
          {...props}
          selectedColorId={selectedColorId}
          onClick={handleColorClick}
          colors={props.colors}
          borderType={props.borderType}
        />
      </LaptopUpOnly>
    </>
  )
}

export default ColorPicker
