import React from "react"
import { FlexProps, Flex } from "@chakra-ui/core"
import Body from "../typography/Body"

type SaleBadgeProps = FlexProps & {}

export default function SaleBadge({ ...props }: SaleBadgeProps) {
  return (
    <Flex
      width="50px"
      height="50px"
      borderRadius="50%"
      backgroundColor="primary"
      align="center"
      justify="center"
      {...props}
    >
      <Body color="white">Sale</Body>
    </Flex>
  )
}
