import Axios from "axios"
import qs from "qs"

export const getJudgemeProduct = (handle: string) => {
  return Axios.get(
    `/.netlify/functions/getJudgemeProduct?${qs.stringify({
      handle,
    })}`
  )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export const getJudgemeAvgRating = (handle: string) => {
  return Axios.get(
    `/.netlify/functions/getJudgemeProductWidget?${qs.stringify({
      handle,
    })}`
  )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
    })
}

export interface JudgmeReview {
  id: number
  external_id: number
  email: string
  name: string
  phone: string
}
export interface GetJudgemeReviewsResponseType {
  current_page: number
  per_page: number
  reviews: Array<{
    id: number
    title: string
    body: string
    rating: number
    review: JudgmeReview
  }>
}

// Fetch one page of reviews to populate metadata
export const getJudgemeReviews = (
  product_id: string,
  page = 1,
  pageSize = 10
) => {
  return Axios.get(
    `/.netlify/functions/getJudgemeReviews?${qs.stringify(
      {
        product_id,
        page,
        per_page: pageSize,
      },
      {
        encode: true,
        encodeValuesOnly: true,
      }
    )}`
  )
    .then((response) => response.data as GetJudgemeReviewsResponseType)
    .catch((error) => {
      console.error(error)
    })
}

export const postJudgemeReview = (review: {
  name: string
  email: string
  rating: number
  title?: string
  body: string
  url: string
  platform: string
  id?: number
}) => {
  return Axios.post(`/.netlify/functions/postJudgemeReview`, review)
}
