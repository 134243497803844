import React from "react"
import { FlexProps, Flex } from "@chakra-ui/core"
import Body from "../typography/Body"

type NewBadgeProps = FlexProps & {}

export default function NewBadge({ ...props }: NewBadgeProps) {
  return (
    <Flex
      width="50px"
      height="50px"
      borderRadius="50%"
      backgroundColor="primary"
      align="center"
      justify="center"
      {...props}
    >
      <Body color="white">New</Body>
    </Flex>
  )
}
