import React from "react"
import { Flex, FlexProps, Text } from "@chakra-ui/core"
import { FitType } from "./QuickFit"
import Body from "../typography/Body"

export type FitBadgeProps = FlexProps & {
  fit?: FitType
  matchPercent?: number
  isBestMatch?: boolean
}

const MatchBadge: React.FC<
  FlexProps & {
    percent: number
    isBestMatch: boolean
  }
> = ({ percent, isBestMatch, ...props }) => (
  <Flex
    flexDir="column"
    alignItems="center"
    justifyContent="center"
    p="0.625rem"
    bg={[
      isBestMatch ? "primary" : "transparent",
      null,
      null,
      null,
      isBestMatch ? "primary" : "primaryLight7",
    ]} // Primary with 0.07 opacity
    borderRadius="0.5rem"
    maxHeight="4rem"
    border={["1px solid", null, null, null, "none"]}
    borderColor={["primaryLight20", null, null, null, "none"]} // Primary with 0.2 opacity
    {...props}
  >
    <Text color={isBestMatch ? "white" : "primary"}>
      <Text
        as="span"
        fontFamily="din"
        fontSize={["1.125rem", null, null, null, "1.3rem"]}
        lineHeight={["1.375rem", null, null, null, "1.625rem"]}
        letterSpacing="0.0625rem"
        textTransform="uppercase"
        fontWeight="bold"
      >
        {percent}
      </Text>
      <Text
        as="span"
        fontFamily="avenirNext"
        lineHeight={["1.125rem", null, null, null, "1.1875rem"]}
        fontSize={["0.8125rem", null, null, null, "0.875rem"]}
      >
        %
      </Text>
    </Text>
    <Body
      size="xs"
      fontSize={["0.8125rem", null, null, null, "0.875rem"]}
      lineHeight={["1.125rem", null, null, null, "1.2rem"]}
      color={isBestMatch ? "white" : "primary"}
    >
      Match!
    </Body>
  </Flex>
)

export default function FitBadge({
  fit,
  matchPercent,
  isBestMatch = false,
  ...props
}: FitBadgeProps) {
  if (!fit && !matchPercent) return null
  return (
    <Flex justify="flex-end" {...props}>
      {matchPercent ? (
        <MatchBadge percent={matchPercent ?? 0} isBestMatch={isBestMatch} />
      ) : (
        <Flex
          h="4rem"
          w="4rem"
          backgroundColor={fit === "fit" ? "primaryLight7" : "sunriseLight7"}
          justifyContent="center"
          alignItems="center"
          p="0.75rem 0.875rem"
          borderRadius="0.5rem"
        >
          <Body
            size="xs"
            color={fit === "fit" ? "primary" : "sunrise"}
            textAlign="center"
          >
            {fit === "fit" ? (
              <>
                {`It's a `}
                <Body
                  as="span"
                  display="block"
                  fontWeight="700"
                  fontFamily="DIN"
                  fontSize="1.125rem"
                >
                  Fit!
                </Body>
              </>
            ) : (
              `Not a Fit`
            )}
          </Body>
        </Flex>
      )}
    </Flex>
  )
}
