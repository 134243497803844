import styled from "@emotion/styled"
import Body from "../typography/Body"

const SlashedText = styled(Body)`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 2px;
    width: 100%;
    border-radius: 4px;
    background-color: red;
    transform: rotate(10deg) translateY(-2px);
    opacity: 0.9;
  }
`

export default SlashedText
