import React from "react"
import {
  Box,
  Flex,
  Text,
  PseudoBox,
  PseudoBoxProps,
  usePrevious,
  Spinner,
  Icon,
  useDisclosure
} from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import ColorPicker, { ColorType } from "../ColorPicker"
import DropDownSelector, { OptionsProps } from "../DropDownSelector"
import Body, { BodyProps } from "../typography/Body"
import { LaptopUpOnly, BelowTabletOnly, bp } from "../../utils/MediaQueries"
import { placeholderImage, decodeShopifyId } from "../../utils"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { getFixedPrice } from "../Cart/utils"
import FitBadge from "../BikeProductPage/FitBadge"
import { FitType } from "../BikeProductPage/QuickFit"
import isString from "lodash/isString"
import FormattedTitle from "../FormattedTitle"
import Stars from "../Stars"
import { Button } from "../Buttons"
import animations from "../../constants/animations"
import useJudgeMeReviews from "../../hooks/useJudgeMeReviews"
import NewBadge from "../../components/NewBadge"
import SaleBadge from "../../components/SaleBadge"
import SlashedText from "../SlashedText"
import { ConnectedAddedToCartModal as AddedToCartModal } from "../../components/Cart/AddedToCartModal"
import DetailsItem from "../../components/DetailsItem"
import { prop } from "ramda"

import { useSelector, useDispatch } from "react-redux"
import { BaseRootState, BaseDispatch } from "../../redux/store"
import { CartItem } from "../../redux/models/checkout"

const BikeName: React.FC<{ children: string; onClick: VoidFunction }> = ({
  children,
  onClick,
}) => (
  <Text
    fontFamily="din"
    fontSize={["1.25rem", null, null, null, "1.375rem"]}
    letterSpacing="0.0313rem"
    color="night"
    lineHeight={["1.4375rem", null, null, null, "1.625rem"]}
    onClick={onClick}
  >
    <FormattedTitle raw={children} />
  </Text>
)

const DescriptionText: React.FC<BodyProps> = ({ children, ...props }) => (
  <Body
    lineHeight={["1.25rem", null, null, null, "1.375rem"]}
    color="dawn"
    mr={["0.5rem", null, null, null, "0"]}
    {...props}
  >
    {children}
  </Body>
)

const BikeImageFluid = styled(GatsbyImage)`
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center !important;
  justify-content: flex-end;
  div:first-of-type {
    padding-bottom: 70% !important;
  }
`

const BikeImageString = styled(Flex)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  justify-content: flex-end;
  padding-top: 70%;
`

export interface BikeVariant {
  uniqueId: string
  productHandle: string
  speed: string
  color: { internalTitle: string; hexCode: string; name: string }
  isNew: boolean
  forSale: boolean
  image?: FluidObject | string
  pricing?: ShopifyVariantPricing
}

export type BaseBikeProductCardProps = {
  title: string
  variants?: Array<BikeVariant>
  to?: string
  types?: any
  gender?: any
  matchPercent?: number // 98 = 98%
  bestMatch?: boolean // Makes match badge a different color
  fitStatus?: FitType | null
  // @ts-ignore
  onClick?: ({ speed: string, color: ColorType, variant: BikeVariant }) => void
  onSpeedChange?: (speed: string) => void
  onColorChange?: (color: ColorType) => void
  showCompare?: boolean
  onCompare?: (variant: BikeVariant) => void
  view?: "normal" | "toggle"
  inStockFilter?: JSX.Element
  selected?: boolean
  featureFirstBike?: boolean
}

export type BikeProductCardProps = Omit<PseudoBoxProps, "onClick"> &
  BaseBikeProductCardProps

const BikeDetailsProductCard: React.FC<BikeProductCardProps> = ({
  title,
  id,
  matchPercent,
  onClick,
  to,
  bestMatch = false,
  fitStatus,
  onSpeedChange = (speed: string) => {},
  onColorChange = (color: ColorType) => {},
  variants,
  showCompare,
  onCompare,
  view = "normal",
  inStockFilter,
  selected = false,
  featureFirstBike = false,
  ...props
}) => {

  const [addedToCart, setAddedToCart] = React.useState(false)
  const addedToCartModal = useDisclosure()

  const { isCartLoading } = useSelector((state: BaseRootState) => ({
    isCartLoading: state.checkout.isLoading,
  }))

  React.useEffect(() => {
    if (addedToCart && isCartLoading === false) {
      setAddedToCart(false)
      addedToCartModal.onOpen()
    }
  }, [isCartLoading])

  const filteredVariants = variants?.filter(
    ({ color, speed }) => color !== null && speed !== null
  )
  if (!filteredVariants || filteredVariants.length === 0) return null

  const [currentShopifyId, setCurrentShopifyId] = React.useState<string>(
    filteredVariants[0].uniqueId
  )
  const [currentSpeed, setCurrentSpeed] = React.useState<string>(
    filteredVariants[0].speed
  )
  const [currentColor, setCurrentColor] = React.useState<string>(
    filteredVariants[0].color?.name
  )
  const currentVariant = filteredVariants.find(
    (variant) => variant.uniqueId === currentShopifyId
  )
  const pricing = currentVariant?.pricing
  const currentPrice = getFixedPrice(pricing?.priceV2.amount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing?.compareAtPriceV2.amount)
    : null
  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock
  const noticeText =
    !pricing || isAvailable
      ? null
      : isPreorder
      ? "(Preorder)"
      : "(Out of Stock)"
  const previousSpeed = usePrevious(currentSpeed)
  const previousColor = usePrevious(currentColor)
  const productHandle = currentVariant?.productHandle
  const previousProductHandle = usePrevious(productHandle)
  const reviews = useJudgeMeReviews(productHandle, {
    lazy: true,
    minimal: true,
  })

  const colorOptions = (filteredVariants?.reduce((acc, cur) => {
    if (
      acc.find(
        (color) =>
          color.colorId === cur.color?.name ||
          color.colorCode === cur.color?.hexCode
      )
    )
      return acc
    return [...acc, { colorId: cur.color?.name, colorCode: cur.color?.hexCode }]
  }, [] as Array<ColorType>) ?? []) as Array<ColorType>

  const isClient = typeof window !== "undefined"

  let location = "------"

  if (isClient) {
    location = window.location.href;
  }
  const quizResultsPage = location.includes("quiz-results")

  const speedOptions = (
    filteredVariants?.reduce((acc, cur, index) => {
      if (acc.find(({ name }) => name === cur.speed)) {
        return acc
      }
      return [
        ...acc,
        { title: `${cur.speed} speed`, name: cur.speed, selected: false },
      ]
    }, []) ?? []
  )
    .sort((a, b) => {
      const aSpeed = parseInt(a.name)
      const bSpeed = parseInt(b.name)
      return aSpeed > bSpeed ? 1 : aSpeed < bSpeed ? -1 : 0
    })
    .map((speed, index) => ({
      ...speed,
      selected: speed.name === currentSpeed,
    })) as Array<OptionsProps>

  const onCurrentColorChange = (color: ColorType) => {
    setCurrentColor(color.colorId)
    onColorChange(color)
  }

  const onCurrentSpeedChange = (speed: string) => {
    setCurrentSpeed(speed)
    onSpeedChange(speed)
  }

  const handleClick = () => {
    // if(quizResultsPage) {
      onClick?.({
        speed: currentSpeed,
        color: currentColor,
        variant: currentVariant,
      })
    // }
  }

  const dispatch = useDispatch<BaseDispatch>()

  const onAddToCart = () => {
    let contentfulProductId = '0'
    let contentfulVariantId = '0'

    bikes?.map((bike) => {
      bike?.speeds?.map((speed) => {
        if(speed?.speed == currentVariant?.speed) {
          speed?.variants?.map((variant) => {
            if(variant?.color?.internalTitle == currentVariant?.color.internalTitle) {
              contentfulProductId = speed.contentful_id
              contentfulVariantId = variant?.contentful_id ? variant?.contentful_id : '0'
            }
          })
        }
      })
    })
    const childCartItems: CartItem[] = []
    let itemList = [
      {
        cartItem: {
          type: "bike",
          productSlug: id,
          contentfulProductId: contentfulProductId,
          contentfulVariantId: contentfulVariantId,
          variantId: currentShopifyId,
          // isPreorder,
          // preorderInfo: preorderLabel,
        } as CartItem,
        childCartItems,
      },
    ]
    dispatch.checkout.addToCartBulk(itemList)
    setAddedToCart(true)
  }

  const handleMouseEnter = () => {
    // Fetch reviews on demand
    if (
      view !== "toggle" &&
      !reviews.fetched &&
      currentVariant?.productHandle
    ) {
      reviews.getJudgemeData()
    }
  }
  // Fetch review product/speed was changed
  React.useEffect(() => {
    if (
      !reviews.fetched &&
      !reviews.isLoading &&
      previousProductHandle &&
      productHandle &&
      previousProductHandle !== productHandle
    ) {
      reviews.getJudgemeData()
    }
  }, [reviews.fetched, reviews.isLoading, productHandle, previousProductHandle])

  // Sync default speed/color variants
  React.useEffect(() => {
    const newVariant = filteredVariants.find(
      (variant) =>
        variant.speed === currentSpeed && variant.color?.name === currentColor
    )

    if (newVariant) {
      setCurrentShopifyId(newVariant.uniqueId)
    } else {
      // There isn't a variant with the new speed-color combo
      // Find a default variant to set:
      if (currentSpeed !== previousSpeed) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.speed === currentSpeed
        )
        if (defaultVariant) {
          onCurrentColorChange({
            colorId: defaultVariant.color.name,
            colorCode: defaultVariant.color.hexCode,
          })
        }
      } else if (currentColor !== previousColor) {
        const defaultVariant = filteredVariants.find(
          (variant) => variant.color.name === currentColor
        )
        if (defaultVariant) {
          onCurrentSpeedChange(defaultVariant.speed)
        }
      } else {
        setCurrentShopifyId(filteredVariants[0].uniqueId)
      }
    }
  }, [filteredVariants, currentColor, currentSpeed])

  const {
    quiz: {
      previousQuizAnswers,
      discount
    },
    bikes: {
      bikes
    }
  } = useSelector((state: BaseRootState) => state)
  
  const currentTimestamp = Date.now()
  let discountedPrice = currentPrice
  if(currentTimestamp - parseInt(previousQuizAnswers[0]?.date) < 86400000){
    discountedPrice = '$' + (pricing?.priceV2.amount - pricing?.priceV2.amount * (discount/100))
  }

  return (
    <PseudoBox
      pos="relative"
      display="flex"
      role="group"
      // h={["auto", null, null, null, "33.75rem"]}
      minH={["20.5rem", null, null, null, "0"]}
      flexDir="column"
      cursor="pointer"
      borderRadius="0.5rem"
      pb={["2.25rem", null, null, null, "5%"]}
      borderBottom={["1px solid", null, null, null, "none"]}
      borderBottomColor={["dividerLine", null, null, null, "none"]}
      border={[null, null, null, null, "1px solid"]}
      className="featuredBikeContainer"
      borderColor={[
        null,
        null,
        null,
        null,
        view === "toggle"
          ? selected
            ? "primary"
            : "dividerLine"
          : "transparent",
      ]}
      _hover={{
        bg: "white",
      }}
      onMouseEnter={handleMouseEnter}
      {...props}
    >
      {/* Toggle mode checkbox */}
      {view === "toggle" && (
        <Flex
          display={bp("none", "flex")}
          pos="absolute"
          top={0}
          right={0}
          zIndex={11}
          borderRadius="0 0.3125rem 0 0.5rem"
          backgroundColor={selected ? "primary" : "noon"}
          width={featureFirstBike ? "4.025rem" : "2.025rem"}
          height="2.025rem"
          align="center"
          justify="center"
        >
          {selected && <Icon name="check" color="white" />}
        </Flex>
      )}
      {inStockFilter}
      <Box 
        as={to && view !== "toggle" ? Link : "div"}
        // @ts-ignore
        to={`${to}/?variant=${decodeShopifyId(currentShopifyId)}`}
        mx={["0", null, null, null, "0.9375rem"]}
        // h={["14.5rem", null, null, null, "25rem"]}
        onClick={!quizResultsPage ? handleClick : ()=>{}}
        className="featured_bike"
        zIndex={10}
      >
        {featureFirstBike && (
          <LaptopUpOnly>
            <Box className="featured_head">
              <Box className="featured_subtitme">Time to Celebrate, Here's</Box>
              <Box className="featured_title ">
                <Box className="title">Your Perfect Bike</Box>
                <Box className="long-arrow-right"></Box>  
              </Box>
            </Box>
          </LaptopUpOnly>
        )}
        {isString(currentVariant?.image) ? (
          <Box zIndex={4}>
            <BikeImageString
              backgroundImage={`url(${
                currentVariant?.image ?? "placeholderImage.src"
              })`}
            >
              {currentVariant?.isNew && (
                <NewBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              {currentVariant?.forSale && (
                <SaleBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              <FitBadge
                pos="absolute"
                top={view === "toggle" ? "2.5rem" : "0.875rem"}
                right={0}
                zIndex={1}
                matchPercent={matchPercent}
                isBestMatch={bestMatch}
                fit={fitStatus}
              />
            </BikeImageString>
            </Box>
        ) : (
          <LaptopUpOnly>
            <Box pos="relative">
              {featureFirstBike && (
                <Flex
                justifyContent="space-between"
                px="2rem"
                mt="-50px"
                zIndex={10}
              >
                <Flex w="20%" pt="80px" flexDir="column">
                  <BikeName onClick={handleClick}>{title}</BikeName>
                  <Flex flexWrap="wrap" align="center">
                    <Box pos="relative" left="-1rem" pb="0.5rem">
                      <DropDownSelector
                        title="speed"
                        options={speedOptions}
                        dropDownType="single"
                        onOptionSelected={onCurrentSpeedChange}
                        showChevron
                        menuListProps={{
                          zIndex: 3,
                        }}
                      />
                      {/* <ColorPicker
                        selectedColorId={currentColor}
                        colors={colorOptions}
                        onColorClick={onCurrentColorChange}
                        gridRowGap="0.25rem"
                        justifyContent="flex-end"
                      /> */}
                    </Box>
                    <DescriptionText flexShrink={0}>
                      <Flex as="span" pt="0.5rem" fontSize="1rem">
                        <span>{currentPrice}</span>
                        {compareAtPrice && (
                          <SlashedText as="span" ml="0.5rem" color='black' fontWeight='bold'>
                            {compareAtPrice}
                          </SlashedText>
                        )}
                      </Flex>
                      
                      {(currentTimestamp - parseInt(previousQuizAnswers[0]?.date) < 86400000) ? (
                        <Box pt="1rem" color="#00C4D1" fontWeight="semibold">
                          <Box>24 Hours Only</Box>
                          <Box mb="1rem">Extra {discount}% Off (-{getFixedPrice(""+(pricing?.priceV2.amount * (discount/100)).toFixed(2)+"")})</Box>
                          <Box as="span" color='black' fontWeight='bold' fontSize="1rem">
                            ${(pricing?.priceV2.amount - pricing?.priceV2.amount * (discount/100)).toFixed(2)}
                          </Box>
                          {noticeText ? (
                            <Box as="span">
                              {noticeText}
                            </Box>
                          ):(
                            <Box pt="1rem">
                              <Button
                                flexGrow={1}
                                onClick={onAddToCart}
                                pl="1.5rem"
                                pr="1.5rem"
                                zIndex={12}
                              >Add To Cart
                              </Button>
                            </Box>
                          )}
                          
                        </Box>
                      
                        
                      ):(
                        console.log('5% Discount')
                      )}
                    </DescriptionText>
                  </Flex>
                </Flex>
                <Flex w="75%" flexDir="column" className="featuredImage"
                onClick={handleClick}
                zIndex={4}>
                    <BikeImageFluid
                      fluid={currentVariant?.image ?? placeholderImage}
                      backgroundColor="white"
                      fadeIn
                      imgStyle={{
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  {!currentVariant?.forSale && currentVariant?.isNew && (
                    <NewBadge
                      position="absolute"
                      top={bp("0", "0.9375rem")}
                      left={0}
                      zIndex={1}
                    />
                  )}
                  {currentVariant?.forSale && (
                    <SaleBadge
                      position="absolute"
                      top={bp("0", "0.9375rem")}
                      left={0}
                      zIndex={1}
                    />
                  )}
                  <FitBadge
                    pos="absolute"
                    top={view === "toggle" ? "2.5rem" : "0.875rem"}
                    right={0}
                    zIndex={1}
                    matchPercent={matchPercent}
                    isBestMatch={bestMatch}
                    fit={fitStatus}
                  />
                </Flex>
              </Flex>
              )}
              { !featureFirstBike && (
                <Box onClick={handleClick}>
                <BikeImageFluid
                  fluid={currentVariant?.image ?? placeholderImage}
                  backgroundColor="white"
                  fadeIn
                  imgStyle={{
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
                {!currentVariant?.forSale && currentVariant?.isNew && (
                  <NewBadge
                    position="absolute"
                    top={bp("0", "0.9375rem")}
                    left={0}
                    zIndex={1}
                  />
                )}
                {currentVariant?.forSale && (
                  <SaleBadge
                    position="absolute"
                    top={bp("0", "0.9375rem")}
                    left={0}
                    zIndex={1}
                  />
                )}
                <FitBadge
                  pos="absolute"
                  top={view === "toggle" ? "2.5rem" : "0.875rem"}
                  right={0}
                  zIndex={1}
                  matchPercent={matchPercent}
                  isBestMatch={bestMatch}
                  fit={fitStatus}
                />
                </Box>
              )}
            </Box>
          </LaptopUpOnly>
        )}
      </Box>
      <BelowTabletOnly>
        {featureFirstBike && (
          <Box className="featured_head">
            <Box className="featured_subtitme" fontSize="4.4vw">Time to Celebrate, Here's</Box>
            <Box className="featured_title" fontSize="9vw">Your Perfect Bike</Box>
          </Box>
        )}
        <Box
          as={to && view !== "toggle" ? Link : "div"}
          // @ts-ignore
          to={`${to}/?variant=${decodeShopifyId(currentShopifyId)}`}
          mx={["0", null, null, null, "0.9375rem"]}
          // h={["14.5rem", null, null, null, "25rem"]}
          // onClick={handleClick}
        >
          {isString(currentVariant?.image) ? (
            <BikeImageString
              onClick={handleClick}
              backgroundImage={`url(${
                currentVariant?.image ?? placeholderImage.src
              })`}
            >
              {currentVariant?.isNew && (
                <NewBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              {currentVariant?.forSale && (
                <SaleBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              <FitBadge
                pos="absolute"
                top={view === "toggle" ? "2.5rem" : "0.875rem"}
                right={0}
                zIndex={1}
                matchPercent={matchPercent}
                isBestMatch={bestMatch}
                fit={fitStatus}
              />
            </BikeImageString>
          ) : (
            <Box pos="relative"
            onClick={handleClick}
            >
              <BikeImageFluid
                fluid={currentVariant?.image ?? placeholderImage}
                backgroundColor="white"
                fadeIn
                imgStyle={{
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              {!currentVariant?.forSale && currentVariant?.isNew && (
                <NewBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              {currentVariant?.forSale && (
                <SaleBadge
                  position="absolute"
                  top={bp("0", "0.9375rem")}
                  left={0}
                  zIndex={1}
                />
              )}
              <FitBadge
                pos="absolute"
                top={view === "toggle" ? "2.5rem" : "0.875rem"}
                right={0}
                zIndex={1}
                matchPercent={matchPercent}
                isBestMatch={bestMatch}
                fit={fitStatus}
              />
            </Box>
          )}
        </Box>

        <Flex flexDir="column" mt="1rem">
        {featureFirstBike && (
            <Box>
              <Box
                onClick={handleClick}
              >
                <Text
                  fontFamily="din"
                  fontSize="3rem"
                  letterSpacing="0.0313rem"
                  color="night"
                  lineHeight="3rem"
                  className="mFeaturedtitle"
                >
                  {title}
                </Text>
              </Box>
              <Box pos="relative" left="-1rem" pb="0.5rem">
                <DropDownSelector
                  title="speed"
                  options={speedOptions}
                  dropDownType="single"
                  onOptionSelected={onCurrentSpeedChange}
                  showChevron
                  menuListProps={{
                    zIndex: 3,
                  }}
                />
              </Box>
              <DescriptionText flexShrink={0}>
                <Flex as="span" pt="0.5rem" fontSize="1.5rem">
                  {compareAtPrice && (
                    <SlashedText as="span" fontSize="1.5rem">
                      {compareAtPrice}
                    </SlashedText>
                  )}
                  <Flex as="span" pt="0.5rem" ml="0.5rem" fontWeight='bold' color="black">{currentPrice}</Flex>
                </Flex>
                
                {(currentTimestamp - parseInt(previousQuizAnswers[0]?.date) < 86400000) ? (
                  <Box fontSize="1.3rem" lineHeight="1.3rem" mt="2rem" color="#00C4D1" fontWeight="semibold">
                    <Box>24 Hours Only</Box>
                    <Box mb="2.5rem">Extra {discount}% Off (-{getFixedPrice(""+pricing?.priceV2.amount * (discount/100)+"")})</Box>
                    <Box as="span" color='black' fontWeight='bold' fontSize="1.3rem" mt="2rem">
                      ${pricing?.priceV2.amount - pricing?.priceV2.amount * (discount/100)}
                    </Box>
                    {noticeText ? (
                      <Box as="span">
                        {noticeText}
                      </Box>
                    ):(
                      <Box pt="2rem">
                        <Button
                          flexGrow={1}
                          onClick={onAddToCart}
                          pl="2.5rem"
                          pr="2.5rem"
                          pt="0.5rem"
                          pb="0.5rem"
                          fontSize="1rem"
                        >Add To Cart
                        </Button>
                      </Box>
                    )}
                    
                  </Box>
                  
                ):(
                  console.log('5% Discount')
                )}
              </DescriptionText>

            </Box>
          )}
          {!featureFirstBike && (
            <Box>
              <BikeName onClick={handleClick}>{title}</BikeName>
              <Flex mt="0.6875rem" wrap="wrap">
                <DescriptionText>
                  <Flex as="span">
                    <span>{currentPrice}</span>
                    {compareAtPrice && (
                      <SlashedText as="span" ml="0.5rem">
                        {compareAtPrice}
                      </SlashedText>
                    )}
                    {noticeText && (
                      <Box as="span" ml="0.5rem">
                        {noticeText}
                      </Box>
                    )}
                    ,
                  </Flex>
                </DescriptionText>
                <DropDownSelector
                  title="speed"
                  options={speedOptions}
                  dropDownType="single"
                  onOptionSelected={onCurrentSpeedChange}
                  showChevron
                  menuListProps={{
                    zIndex: 3,
                  }}
                  mobileHeight="unset"
                />
                <ColorPicker
                  selectedColorId={currentColor}
                  colors={colorOptions}
                  onColorClick={onCurrentColorChange}
                  gridRowGap="0.25rem"
                  justifyContent="flex-end"
                />
              </Flex>
            </Box>
          )}
        </Flex>
      </BelowTabletOnly>
      <LaptopUpOnly>
        {!featureFirstBike && (
        <Flex
          w="100%"
          justifyContent="space-between"
          px="2rem"
          pb="1rem"
          zIndex={10}
        >

          <Flex flexDir="column" flexGrow={1} pb="0.5rem">
            <BikeName onClick={handleClick}>{title}</BikeName>
            <Flex flexWrap="wrap" align="center">
              <Box pos="relative" left="-1rem">
                <DropDownSelector
                  title="speed"
                  options={speedOptions}
                  dropDownType="single"
                  onOptionSelected={onCurrentSpeedChange}
                  showChevron
                  menuListProps={{
                    zIndex: 3,
                  }}
                />
              </Box>
              <DescriptionText flexShrink={0}>
                <Flex as="span">
                  <span>{currentPrice}</span>
                  {compareAtPrice && (
                    <SlashedText as="span" ml="0.5rem">
                      {compareAtPrice}
                    </SlashedText>
                  )}
                  {noticeText && (
                    <Box as="span" ml="0.5rem">
                      {noticeText}
                    </Box>
                  )}
                </Flex>
              </DescriptionText>
            </Flex>
          </Flex>
          <Flex
            flexWrap="wrap"
            flexGrow={1}
            maxW="45%"
            justify="flex-end"
            alignContent="flex-start"
          >
            <ColorPicker
              selectedColorId={currentColor}
              colors={colorOptions}
              onColorClick={onCurrentColorChange}
              gridRowGap="0.25rem"
              justifyContent="flex-end"
            />
          </Flex>
        </Flex>
        )}
        {/* Box Shadow */}
        <PseudoBox
          position="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          boxShadow="0rem 1.5625rem 6.25rem rgba(18, 25, 35, 0.05)"
          opacity={0}
          _groupHover={{ opacity: 1 }}
          transition="opacity 200ms ease"
          zIndex={1}
          pointerEvents="none"
        />
        {/* Hover section */}
        {view !== "toggle" && (
          <PseudoBox
            position="absolute"
            top="95%" // 100% - padding bottom (5%) on container
            left="0"
            zIndex={2}
            h="5rem"
            w="100%"
            px={[null, null, null, null, "2rem"]}
            borderRadius="0 0 0.5rem 0.5rem"
            transform="scaleY(0)"
            transformOrigin="top center"
            transition={`transform 200ms ${animations.bezier}`}
            _groupHover={{
              transform: "scaleY(1)",
            }}
            backgroundColor="white"
            boxShadow="big"
            borderTop="1px solid"
            borderColor="dividerLine"
          >
            <PseudoBox
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              h="100%"
              opacity={0}
              transform="scaleY(3.5)"
              transformOrigin="top center"
              transition={`transform 200ms ${animations.bezier}, opacity 150ms ${animations.bezier}`}
              _groupHover={{
                opacity: 1,
                transform: "scaleY(1)",
              }}
            >
              <Flex align="center" flexWrap="wrap" gridRowGap="0.5rem">
                {reviews.isLoading ? (
                  <Spinner size="sm" color="primary" label="Loading reviews" />
                ) : (
                  <>
                    <Stars
                      value={reviews.avgRating || 0}
                      mr={["0.5rem", null, null, null, null, "1rem"]}
                    />
                    {reviews.numReviews !== 0 && (
                      <Body size="md" color="primary">
                        {reviews.numReviews} Reviews
                      </Body>
                    )}
                  </>
                )}
              </Flex>
              {showCompare && (
                <Button
                  theme="secondary"
                  whiteSpace="nowrap"
                  onClick={() => {
                    if (currentVariant) {
                      onCompare?.(currentVariant)
                    }
                  }}
                >
                  Compare
                </Button>
              )}
            </PseudoBox>
          </PseudoBox>
        )}
      </LaptopUpOnly>
      
      <AddedToCartModal
          isOpen={addedToCartModal.isOpen}
          onClose={addedToCartModal.onClose}
          formattedTitle={title}
          image={
            (currentVariant?.image ??
              placeholderImage) as FluidObject
          }
          details={
            <>
              <DetailsItem>{currentVariant?.color?.name}</DetailsItem>
            </>
          }
          price={discountedPrice}
        />
    </PseudoBox>
  )
}

export default BikeDetailsProductCard
