import React from "react"
import { Tooltip, TooltipProps as ChakraTooltipProps } from "@chakra-ui/core"

type TooltipProps = Omit<ChakraTooltipProps, "position" | "aria-label"> & {
  label: string
  isOpen?: boolean
  position?: "top" | "bottom" | "left" | "right"
}

const ToolTip: React.FC<TooltipProps> = ({
  children,
  label,
  position = "top",
  ...props
}) => {
  return (
    <Tooltip
      hasArrow
      placement={position}
      bg="night"
      p="0.875rem 1.1875rem"
      borderRadius="0.25rem"
      color="white"
      fontFamily="avenirNext"
      fontSize="bodySm"
      fontWeight="medium"
      lineHeight="1.25rem"
      letterSpacing="thin100"
      label={label}
      shouldWrapChildren
      {...{ "aria-label": label }}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default ToolTip
