import React from "react"
import { Flex, FlexProps, Icon, Box } from "@chakra-ui/core"
import * as R from "ramda"

type StarsProps = FlexProps & {
  value?: number
}

export default function Stars({ value = 0, ...props }: StarsProps) {
  let number: number
  let showHalf = false
  const floor = Math.floor(value)
  if (value === 0 || value % floor === 0) {
    // Non-decimal number
    number = value
  } else if (value % floor > 0.5) {
    // Decimal greater than half; Round Up
    number = Math.ceil(value)
  } else {
    // Decimal less than half; Show half-star
    number = floor
    showHalf = true
  }

  const common = {
    size: "1.0288rem",
    name: "star",
    mr: "0.2219rem",
  }

  // Cap number to 5 max
  number = Math.min(number, 5)

  return (
    <Flex align="center" justify="center" {...props}>
      {R.range(0, number).map((value) => (
        <Icon key={value} {...common} color="primary" />
      ))}
      {showHalf && (
        <Box pos="relative">
          <Icon {...common} color="noon" />
          <Box
            pos="absolute"
            top="0px"
            left="0px"
            overflow="hidden"
            width={`calc(${common.size}/2)`}
          >
            <Icon {...common} color="primary" />
          </Box>
        </Box>
      )}
      {R.range(number, showHalf ? 4 : 5).map((value) => (
        <Icon key={value} {...common} color="noon" />
      ))}
    </Flex>
  )
}
